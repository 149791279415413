export default {
  init() {
    const openEnterprise = this.openEnterprise.bind(this);
    const header = document.getElementById('header');

    const openMenu = document.querySelector('.open-menu');
    const hamburguerIcon = document.querySelector('.hamburger-icon');
    const backgroundLight = document.querySelector('.bg-light');
    const navbarTopMenu = document.querySelector('.navbar-top-menu-wrapper');
    const navbarMainMenu = document.querySelector('.navbar-main-menu-wrapper');
    const backMainMenu = navbarTopMenu.querySelector('.back-main-menu');
    const navbarBrand = navbarTopMenu.querySelector('.navbar-brand');

    openMenu.addEventListener('click', function () {

      const menuShow = navbarMainMenu.querySelector('.navbar-collapse').classList.contains('show');
      if (!menuShow) {
        hamburguerIcon.classList.add('open');
        header.classList.add('open-main-menu');
        navbarBrand.classList.add('navbar-brand-none');

      } else {
        hamburguerIcon.classList.remove('open');
        header.classList.remove('open-main-menu');
        navbarBrand.classList.remove('navbar-brand-none');
      }

      navbarTopMenu.classList.add('bg-light');
      navbarMainMenu.classList.add('bg-light');
    });

    const allDropdowns = document.querySelectorAll('.nav-item.dropdown');

    allDropdowns.forEach(function (item) {
      let timer;
      const menuShow = navbarMainMenu.querySelector('.show');
      const submenu = item.querySelector('.dropdown-menu');

      item.addEventListener('mouseenter', function () {
        clearTimeout(timer);

        // Adiciona classes de destaque aos menus
        navbarTopMenu.classList.add('bg-light');
        navbarMainMenu.classList.add('bg-light');

        // Adiciona classe ao item atual
        item.classList.add('highlighted');

        // Exibe o submenu
        submenu.style.display = 'block';
        submenu.style.visibility = 'visible';

        navbarBrand.classList.add('navbar-brand-none');
        navbarBrand.classList.add('d-none', 'd-md-block');
        backMainMenu.classList.remove('d-none');
        backMainMenu.classList.add('d-block');
      });

      item.addEventListener('mouseleave', function () {
        // Remove classe do item atual
        item.classList.remove('highlighted');
        navbarBrand.classList.remove('d-none', 'd-md-block');
        backMainMenu.classList.add('d-none');
        backMainMenu.classList.remove('d-block');

        timer = setTimeout(function () {
          submenu.style.display = 'none';
          // Remove as classes de destaque dos menus apenas se o mouse não estiver sobre nenhum item de menu dropdown ou submenu
          if (menuShow && !backgroundLight && !navbarTopMenu.querySelector(':hover') && !navbarMainMenu.querySelector(':hover')) {
            menuShow.classList.remove('show');
            hamburguerIcon.classList.remove('open');
            navbarMainMenu.classList.remove('bg-light');
            navbarTopMenu.classList.remove('bg-light');
          }
        }, 300);
      });

      submenu.addEventListener('mouseenter', function () {
        clearTimeout(timer);
        // Adiciona classes de destaque aos menus
        navbarTopMenu.classList.add('bg-light');
        navbarMainMenu.classList.add('bg-light');
      });

      submenu.addEventListener('mouseleave', function () {
        timer = setTimeout(function () {
          submenu.style.display = 'none';
          // Remove as classes de destaque dos menus apenas se o mouse não estiver sobre nenhum item de menu dropdown ou submenu
          if (menuShow && !backgroundLight && !navbarTopMenu.querySelector(':hover') && !navbarMainMenu.querySelector(':hover')) {
            menuShow.classList.remove('show');
            hamburguerIcon.classList.remove('open');
            navbarMainMenu.classList.remove('bg-light');
            navbarTopMenu.classList.remove('bg-light');
          }
        }, 300);
      });
    });

    navbarMainMenu.addEventListener('mouseleave', function () {
      const menuShow = navbarMainMenu.querySelector('.show');
      // const navMain = navbarMainMenu.querySelector('nav');
      // if (menuShow) {
      //   navMain.style.display = 'block';
      // } else {
      //   navMain.style.display = 'none';
      // }
      // Remove a classe de destaque apenas se o mouse não estiver sobre nenhum item de menu dropdown ou submenu
      if (!menuShow && !backgroundLight && !navbarTopMenu.querySelector(':hover') && !navbarMainMenu.querySelector(':hover')) {
        navbarMainMenu.classList.remove('bg-light');
        navbarTopMenu.classList.remove('bg-light');
      }
    });

    navbarTopMenu.addEventListener('mouseleave', function () {
      const menuShow = navbarMainMenu.querySelector('.show');
      // const navMain = navbarMainMenu.querySelector('nav');
      // if (menuShow) {
      //   navMain.style.display = 'block';
      // } else {
      //   navMain.style.display = 'none';
      // }
      // Remove a classe de destaque apenas se o mouse não estiver sobre nenhum item de menu dropdown ou submenu
      if (!menuShow && !backgroundLight && !navbarTopMenu.querySelector(':hover') && !navbarMainMenu.querySelector(':hover')) {
        navbarMainMenu.classList.remove('bg-light');
        navbarTopMenu.classList.remove('bg-light');
      }
    });

    const headerHeight = header.offsetHeight;

    window.addEventListener('scroll', () => {
      if (window.scrollY > headerHeight) {
        header.classList.add('fixed');
      } else {
        header.classList.remove('fixed');
      }
    });

    document.querySelectorAll('.tab-bar .bar-item').forEach(item => {
      item.addEventListener('click', function () {

        const enterprise = this.getAttribute('data-enterprise');
        openEnterprise(enterprise);
    
        document.querySelectorAll('.bar-item.active').forEach(activeItem => {
          activeItem.classList.remove('active');
        });
        this.classList.add('active');

      });
    });

    const searchTable = document.getElementById('searchTable');
    if (searchTable) {
      searchTable.addEventListener('keyup', () => {
        this.searchTable();
      })
    }

    document.querySelectorAll('.dropdown > a').forEach(link => {
      link.addEventListener('click', function (event) {
        event.preventDefault();
        location.href = this.href;
      });
    });

    const selectLanguage = document.querySelectorAll('.language-selector');
    
    selectLanguage.forEach(function (select) {
      select.addEventListener('change', function() {
          var url = this.value;
          if (url) {
              window.location.href = url;
          }
      });
    });
    // document.querySelector('.select-selected').addEventListener('click', function () {
    //   this.nextSibling.classList.toggle('select-hide');
    // });
    // document.querySelectorAll('.select-items div').forEach(function (item) {
    //   item.addEventListener('click', function () {
    //     // Atualiza o texto exibido no dropdown
    //     document.querySelector('.select-selected').innerHTML = this.innerHTML;
    //     // Oculta as opções
    //     document.querySelector('.select-items').classList.add('select-hide');
    //     // Redireciona para a URL correspondente
    //     var url = this.getAttribute('data-url');
    //     if (url) {
    //       window.location.href = url;
    //     }
    //   });
    // });

    // Select Country
    var sels = $('.fake-sel');

    var imgs_ = [
      ['https://flagcdn.com/br.svg', 'https://flagcdn.com/us.svg'],
    ];

    // Função para atualizar o elemento selecionado com base no local storage
    function updateSelectedLanguage($t, value) {
      var $parent = $t.closest('.fake-sel-wrap');
      $parent.find('li').each(function () {
        if ($(this).text().trim() === value) {
          $(this).addClass('ativo').siblings().removeClass('ativo');
          $parent.find('li:first').html($(this).html());
        }
      });
    }

    sels.each(function (x) {
      var $t = $(this);
      var opts_ = '', first;
      var savedLanguage = localStorage.getItem('selectedLanguage'); // Recupera o idioma selecionado

      $t.find('option').each(function (i) {
        if (i == 0) {
          first = '<li><img src=\'' + imgs_[x][i] + '\'>' + $(this).text() + '</li>';
        }
        opts_ += '<li' + (i == 0 ? ' class=\'ativo\'' : '') + ' data-url="' + $(this).val() + '"><img src=\'' + imgs_[x][i] + '\'>' + $(this).text() + '</li>';
      });

      $t.wrap('<div class=\'fake-sel-wrap\'></div>')
        .hide()
        .parent()
        .css('width', $t.outerWidth() + 60)
        .append('<ul>' + first + opts_ + '</ul>')
        .find('ul')
        .on('click', function (e) {
          e.stopPropagation();
          $(this).toggleClass('ativo');
        }).find('li:not(:first)')
        .on('click', function () {
          $(this)
            .addClass('ativo')
            .siblings()
            .removeClass('ativo')
            .parent()
            .find('li:first')
            .html($(this).html());
          // Atribui o valor ao select escondido
          $t.val($(this).text());
          // Armazena a seleção no local storage
          localStorage.setItem('selectedLanguage', $(this).text().trim());

          // Dispara o evento de mudança
          $(this).trigger('change');
        });

      // Atualiza o fake select com a linguagem salva
      if (savedLanguage) {
        updateSelectedLanguage($t, savedLanguage);
      }
    });

    $(document).on('click', function () {
      $('.fake-sel-wrap ul').removeClass('ativo');
    });

    // Evento change para o fake select
    $('.fake-sel-wrap ul li:not(:first)').on('change', function () {
      var url = $(this).data('url');
      if (url) {
        window.location.href = url;
      }
    });
  },

  searchTable() {
    var e, t, n, o, r, l, a;
    for (e = document.getElementById('searchTable'), t = e.value.toUpperCase(), n = document.getElementById('certTable'), o = n.getElementsByTagName('tr'), l = 0; l < o.length; l++)
      (r = o[l].getElementsByTagName('td')[0]) && (a = r.textContent || r.innerText, a.toUpperCase().indexOf(t) > -1 ? o[l].style.display = '' : o[l].style.display = 'none')
  },

  openEnterprise(enterpriseName) {

    var i;

    var element = document.getElementsByClassName('enterprise');

    for (i = 0; i < element.length; i++) {
      element[i].style.display = 'none';
    }

    document.getElementById(enterpriseName).style.display = 'block';

  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
