export default {
  init() {
    const form = document.getElementById('newsletterForm');
    const message = document.getElementById('message');
    if (form) {
      form.addEventListener('submit', event => {
        event.preventDefault();
        const formData = new FormData(form);
        // eslint-disable-next-line no-undef
        formData.append('nonce', wp_ajax_object.ajax_nonce);
        formData.append('action', 'wp_send_form_news');
        const plainFormData = Object.fromEntries(formData.entries());
        $.ajax({
          //eslint-disable-next-line no-undef
          url: wp_ajax_object.ajax_url,
          data: plainFormData,
          type: 'POST',
          beforeSend: function () {
            form.reset();
            console.log('enviando ...')
          },
          success: function (data) {

            const elem = document.createElement('p')
            const node = document.createTextNode(data.message)
            elem.appendChild(node)
            message.appendChild(elem)
            message.style.display = 'block';

            setTimeout(function () {
              message.style.display = 'none';
              message.innerHTML = '';
            }, 5000)

          },
        });

        return false;
      });
    }
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
